import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../../components/layout';
import styles from './pay-bonuses.module.scss';
import HeroImage from '../../components/hero-image/hero-image';
import HeroImageMini from '../../components/hero-image/hero-image-mini';
import PayOverviewSection from '../../components/travel-nursing/pay/pay-overview-section';
import PayBonusesSection from '../../components/travel-nursing/pay/pay-bonuses-section';
import PayTaxSection from '../../components/travel-nursing/pay/pay-tax-section';
import { Helmet } from 'react-helmet';

export default ({ data }) => {
  const getTravelNursingPayImageEdge = name => {
    return data.allFile.edges.find(e => e.node.name === name);
  };
  const ogImageUrlPath = getTravelNursingPayImageEdge('travel-nursing-pay-og')
    .node.childImageSharp.fluid.src;
  const fullOgImageUrl = `https://www.trustaff.com${ogImageUrlPath}`;
  return (
    <>
      <Helmet>
        <title>Travel nursing pay - Trustaff</title>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="Trustaff's travel nurses are some of the best compensated healthcare professionals in the industry. Earn as much as $2,800 a week depending on your specialty &amp; experience!"
        />
        <meta property="og:title" content="Travel nursing pay" />
        <meta
          property="og:description"
          content="Trustaff's travel nurses are some of the best compensated healthcare professionals in the industry. Earn as much as $2,800 a week depending on your specialty &amp; experience!"
        />
        <meta property="og:image" content={fullOgImageUrl} />
        <meta
          property="og:url"
          content="https://www.trustaff.com/travel-nursing/pay-bonuses/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Trustaff" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@Trustaff" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout>
        <HeroImage
          fluid={
            getTravelNursingPayImageEdge('travel-nursing-pay').node
              .childImageSharp.fluid
          }
        >
          <div className={'columns'}>
            <div className="column is-7 is-offset-1">
              <div className={styles.heroTextArea}>
                <h1 className={`title is-2 has-text-white`}>
                  Travel nursing pay, salary, &amp; bonuses
                </h1>
                <p className={`content`}>
                  Trustaff's travel nurses are some of the best compensated in
                  the industry. When you take your skills and experience on the
                  road, not only do you get to expand your knowledge and see the
                  country, you can earn as much as <b>$2,800 a week</b>, or much
                  more when you take a{' '}
                  <Link to="/covid19/crisis-jobs/">crisis response job</Link>.
                </p>
                <p className={`content`}>
                  Traveling nurses and other types of healthcare professionals,
                  like allied travelers, typically earn far more than a staff
                  position. Your individual salary or pay will vary by your
                  specialty, certifications, and experience, as well as the
                  location where you take your assignment.
                </p>
              </div>
            </div>
          </div>
        </HeroImage>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <PayOverviewSection
            fluid={
              getTravelNursingPayImageEdge('high-paying-travel-jobs').node
                .childImageSharp.fluid
            }
          />
        </div>
        <div
          className={`section ${styles.sectionNoSidePaddingMobile} ${styles.sectionBackgroundGrey}`}
        >
          <PayBonusesSection />
        </div>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <PayTaxSection
            fluidTop={
              getTravelNursingPayImageEdge('nyc-travel-nursing').node
                .childImageSharp.fluid
            }
            fluidBottom={
              getTravelNursingPayImageEdge('city-cafe').node.childImageSharp
                .fluid
            }
          />
        </div>
        <HeroImageMini
          fluid={
            getTravelNursingPayImageEdge('travel-nursing-bonuses').node
              .childImageSharp.fluid
          }
        />
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  {
    allFile(filter: { relativePath: { regex: "/^travel-nursing-pay/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
