import React from 'react';
import { Link } from 'gatsby';
import styles from './pay-bonuses-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">Earn great bonuses</h3>
          <div className="content">
            <p className={`content`}>
              Not only are our travel nurses well paid, you can also boost your
              salary with a variety of bonuses. The type, amount, and
              availability of each bonus depends on your current assignment, so
              be sure to ask your recruiter when you’re selecting your next
              location!
            </p>
            <p className="content">
              If you have a friend, coworker or a family member who wants to
              learn more about becoming a travel nurse, send them our way. We
              love when you recommend Trustaff, and we'll pay a{' '}
              <Link to="/referrals/">
                <b>referral bonus</b>
              </Link>{' '}
              for your effort.
            </p>
            <p className="content">
              You don't even have to be a current Trustaff employee—and there's
              no limit to the number of referral bonuses you can collect!
            </p>
          </div>
          <Link to="/referrals/" className="button is-info">
            Refer a friend
          </Link>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile} ${styles.testimonial}`}
        >
          <p className={`content ${styles.testimonialtext}`}>
            “My Trustaff recruiter is very knowledgeable and I never have to
            worry if or when I will get a call back. He and his staff are
            ‘Johnny on the Spot’ and always come through for me. Thank you to
            the entire team for always having my back.”
          </p>
          <p className="content">— Linda</p>
        </div>
      </div>
    </div>
  );
};
